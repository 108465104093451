var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"id":"app-bar","app":"","color":"transperent","fixed":"","height":"75","elevate-on-scroll":""}},[_c('v-btn',{attrs:{"elevation":"0","color":_vm.siteInfo.color,"icon":"","outlined":""},on:{"click":function($event){return _vm.setDrawer(!_vm.drawer)}}},[_c('v-icon',[_vm._v(" mdi-menu ")])],1),_c('v-toolbar-title',{staticClass:"hidden-sm-and-down font-weight-light",domProps:{"textContent":_vm._s(_vm.$vuetify.lang.t('$vuetify.dashboard'))}}),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","bottom":"","left":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
var attrsMenu = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
var attrsTooltip = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","text":""}},'v-btn',Object.assign({}, attrsMenu, {attrsTooltip: attrsTooltip}),false),Object.assign({}, onTooltip, onMenu)),[_c('v-icon',[_vm._v("mdi-translate")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.changeLang")))])])]}}])},[_c('v-list',[_c('span',{on:{"click":function($event){return _vm.setCurrentLang('en')}}},[_c('app-bar-item',[_c('v-list-item-title',[_vm._v(" English ")])],1)],1),_c('span',{on:{"click":function($event){return _vm.setCurrentLang('zhHans')}}},[_c('app-bar-item',[_c('v-list-item-title',[_vm._v(" 中文 ")])],1)],1),_c('span',{on:{"click":function($event){return _vm.setCurrentLang('kh')}}},[_c('app-bar-item',[_c('v-list-item-title',[_vm._v(" ភាសាខ្មែរ ")])],1)],1)])],1),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","text":""}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"color":"red","overlap":"","bordered":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('span',[_vm._v("5")])]},proxy:true}],null,true)},[_c('v-icon',[_vm._v("mdi-bell")])],1)],1)]}}])},[_c('v-list',{attrs:{"tile":false,"nav":""}},[_c('div',_vm._l((_vm.notifications),function(n,i){return _c('app-bar-item',{key:("item-" + i)},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(n)}})],1)}),1)])],1),_c('v-menu',{attrs:{"bottom":"","left":"","origin":"top right","transition":"scale-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"mx-4 mb-1 elevation-3",attrs:{"size":"36"}},'v-avatar',attrs,false),on),[_c('img',{attrs:{"src":_vm.profileImg,"alt":"profile picture"}})])]}}])},[_c('v-list',{staticClass:"text-center"},[_c('v-list-item',{staticClass:"d-flex justify-center"},[_c('v-list-item-avatar',{staticClass:"ml-3 elevation-4",attrs:{"size":"90"}},[_c('img',{attrs:{"src":_vm.profileImg,"alt":"profile picture"}})])],1),_c('v-list-item',{attrs:{"color":"blue"}},[_c('v-list-item-content',{staticClass:"pt-0"},[_c('v-list-item-title',{staticClass:"display-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.userInfo.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.userInfo.email)+" ")])],1)],1),_c('v-divider'),_c('div',{staticClass:"my-3",on:{"click":_vm.showDialog}},[_c('app-bar-item',[_c('v-icon',{staticClass:"mx-2"},[_vm._v("mdi-account-edit")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.codeTip5"))+" ")],1)],1),_c('div',{staticClass:"my-3",on:{"click":_vm.logout}},[_c('app-bar-item',[_c('v-icon',{staticClass:"mx-2"},[_vm._v("mdi-logout")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.logout"))+" ")],1)],1)],1)],1),_c('ChangePasswordForm',{attrs:{"dialog":_vm.changePasswordDialog,"passwordForm":_vm.newPasswordForm},on:{"update:dialog":function($event){_vm.changePasswordDialog=$event},"handleData":_vm.changePassword}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }