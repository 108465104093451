<template>
  <v-app-bar id="app-bar" app color="transperent" fixed height="75" elevate-on-scroll>
    <v-btn
      elevation="0"
      :color="siteInfo.color"
      icon
      outlined
      @click="setDrawer(!drawer)"
    >
      <v-icon> mdi-menu </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$vuetify.lang.t('$vuetify.dashboard')"
    />

    <v-spacer />

    <!-- Translate Button -->
    <v-menu
      offset-y
      bottom
      left
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
            <v-btn
              class="ml-2"
              min-width="0"
              text
              v-bind="{ ...attrsMenu, attrsTooltip }"
              v-on="{ ...onTooltip, ...onMenu }"
            >
              <v-icon>mdi-translate</v-icon>
            </v-btn>
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.changeLang") }}</span>
        </v-tooltip>
      </template>
      <v-list>
        <span @click="setCurrentLang('en')">
          <app-bar-item>
            <v-list-item-title> English </v-list-item-title>
          </app-bar-item>
        </span>
        <span @click="setCurrentLang('zhHans')">
          <app-bar-item>
            <v-list-item-title> 中文 </v-list-item-title>
          </app-bar-item>
        </span>
        <span @click="setCurrentLang('kh')">
          <app-bar-item>
            <v-list-item-title> ភាសាខ្មែរ </v-list-item-title>
          </app-bar-item>
        </span>
      </v-list>
    </v-menu>

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-badge color="red" overlap bordered>
            <template v-slot:badge>
              <span>5</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div>
          <app-bar-item v-for="(n, i) in notifications" :key="`item-${i}`">
            <v-list-item-title v-text="n" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>

    <!-- <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="ml-2"
          min-width="0"
          text
          @click="logout"
        >
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </template>
      <span>{{ $vuetify.lang.t("$vuetify.logout") }}</span>
    </v-tooltip> -->

    <v-menu
      bottom
      left
      origin="top right"
      transition="scale-transition"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-avatar
          size="36"
          class="mx-4 mb-1 elevation-3"
          v-bind="attrs"
          v-on="on"
        >
          <img :src="profileImg" alt="profile picture" />
        </v-avatar>
      </template>

      <v-list class="text-center">
        <v-list-item class="d-flex justify-center">
          <v-list-item-avatar size="90" class="ml-3 elevation-4">
            <img :src="profileImg" alt="profile picture" />
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item color="blue">
          <v-list-item-content class="pt-0">
            <v-list-item-title class="display-2 font-weight-bold">
              {{ userInfo.name }}
            </v-list-item-title>

            <v-list-item-subtitle> {{ userInfo.email }} </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider />

        <div class="my-3" @click="showDialog">
          <app-bar-item>
            <v-icon class="mx-2">mdi-account-edit</v-icon>
            {{ $vuetify.lang.t("$vuetify.codeTip5") }}
          </app-bar-item>
        </div>

        <div class="my-3" @click="logout">
          <app-bar-item>
            <v-icon class="mx-2">mdi-logout</v-icon>
            {{ $vuetify.lang.t("$vuetify.logout") }}
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>

    <ChangePasswordForm
      :dialog.sync="changePasswordDialog"
      :passwordForm="newPasswordForm"
      @handleData="changePassword"
    />
  </v-app-bar>
</template>

<script>
// Components
import { VHover, VListItem } from "vuetify/lib";
// Utilities
import { mapState, mapMutations, mapGetters } from "vuex";
import serverConfig from "@/utils/serverConfig";
import { modifyUser } from '@/api/login'

export default {
  name: "DashboardCoreAppBar",

  components: {
    ChangePasswordForm: () => import("@/components/changePassword"),
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover,
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              );
            },
          },
        });
      },
    },
  },

  data: () => ({
    newPasswordForm: {
      opt_type: "pass",
      new_pass: "",
      pass: "",
      password: "",
    },
    changePasswordDialog: false,
    notifications: [
      "Mike John Responded to your email",
      "You have 5 new tasks",
      "You're now friends with Andrew",
      "Another Notification",
      "Another one",
    ],
  }),

  computed: {
    ...mapState(["drawer"]),
    ...mapGetters(["siteInfo", "userInfo"]),
    profileImg() {
      return this.userInfo.avatar
        ? serverConfig.img_url + this.userInfo.avatar
        : serverConfig.blank_profile_img;
    },
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
    showDialog(){
      this.newPasswordForm = {
        opt_type: "pass",
        new_pass: "",
        pass: "",
        password: "",
      }
      this.changePasswordDialog = true
    },
    changePassword(form){
      modifyUser(form).then(res => {
        this.$toast.success(this.$vuetify.lang.t("$vuetify.codeTip4"), {
          onClose: () => this.logout() 
        });

      }).catch(err => {
        this.$toast.error(this.$vuetify.lang.t("$vuetify.incorrectPassowrd"));
      })
    },
    setCurrentLang(lang) {
      this.$vuetify.lang.current = lang;
      localStorage.setItem("lang", lang);
      //temporary solution
      this.$router.go();
    },
    logout() {
      this.$store.dispatch("LogOut").then(
        // this.$router.push({ path: '/login' })
        location.reload()
      );
    },
  },
};
</script>
